import { PostProvider } from 'pages/landings/Saas/postContext';
import React, { Suspense, ComponentType } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// Componente de carga (puede personalizar esto según sea necesario)
const loading = () => <div>Cargando...</div>;

// Lazy load de las vistas necesarias
const Saas = React.lazy(() => import('../pages/landings/Saas/Saas'));
const Demos = React.lazy(() => import('../pages/landings/Demos/Demos'));
const Blog = React.lazy(() => import('../pages/landings/Saas/Blog'));
const Contacto = React.lazy(() => import('../pages/landings/Saas/Contacto'));
const UseCases = React.lazy(() => import('../pages/landings/Saas/use-cases'));
const LandingWrapper = React.lazy(() => import('../pages/landings/Saas/landing-wrapper'));
const Post = React.lazy(() => import('../pages/landings/Saas/Post'));

type LoadComponentProps = {
    component: ComponentType<any>; // Acepta cualquier componente de React
};

// Componente auxiliar para cargar componentes con Suspense
const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            path: '/demos',
            element: <LoadComponent component={Demos} />,
        },
        {
            path: '/',
            element: <LoadComponent component={LandingWrapper} />,
            children: [
                {
                    path: '/',
                    element: <LoadComponent component={Saas} />,
                },
                {
                    path: '/use-cases',
                    element: <LoadComponent component={UseCases} />,
                },
                {
                    path: '/contacto',
                    element: <LoadComponent component={Contacto} />,
                },
                {
                    path: '/blog',
                    element: (
                        <PostProvider>
                            <LoadComponent component={Blog} />,
                        </PostProvider>
                    ),
                },
                {
                    path: '/post',
                    element: (
                        <PostProvider>
                            <LoadComponent component={Post} />,
                        </PostProvider>
                    ),
                },
            ],
        },
    ]);
};

export default AllRoutes;
