import React, { createContext, useState, ReactNode, useContext } from 'react';

// Tipo para el contexto
export type PostContextType = {
    postHtml: {html:string, image:string};
    setPostHtml: ({html, image}: {html:string, image:string}) => void;
};

// Valor predeterminado para evitar undefined
const defaultContextValue: PostContextType = {
    postHtml: {html:'', image:''},
    setPostHtml: () => {},
};

// Inicializa el contexto con un valor predeterminado
const PostContext = createContext<PostContextType>(defaultContextValue);

export const PostProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [postHtml, setPostHtml] = useState<{html:string, image:string}>({html:'', image:''});

    return (
        <PostContext.Provider value={{ postHtml, setPostHtml }}>
            {children}
        </PostContext.Provider>
    );
};

// Hook para usar el contexto
export const usePostContext = (): PostContextType => {
    return useContext(PostContext);
};
